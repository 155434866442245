import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [

    {
      path: "/admin",
      component: () => import("@/views/admin/Index.vue"),
      meta: {
        auth: true,
      },
      children: [
        {
          "path": "organizations",
          component: () => import('@/views/admin/organizations/Index.vue'),
          children: [
            {
              path: "",
              name: "organization-list",
              component: () => import('@/views/admin/organizations/List.vue')
            },
            {
              path: "create-your-organization",
              name: "organization-flow",
              meta: { permission: ['organization-update'] },
              component: () => import('@/views/admin/OrganizationFlow/CreateOrganization.vue')
            },
            {
              path: "create",
              name: "organization-create",
              meta: { permissions: ['organization-create'] },
              component: () => import('@/views/admin/organizations/Form.vue')
            },
            {
              path: ":id",
              name: "organization-show",
              component: () => import('@/views/admin/organizations/Show.vue')
            },
            {
              path: ":id/edit",
              name: "organization-edit",
              meta: { permissions: ['organization-update'] },
              component: () => import('@/views/admin/organizations/Form.vue')
            },
            {
              path: ":id/invoices",
              name: "organization-invoices",
              component: () => import('@/views/admin/billing/List.vue')
            },

          ]
        },
        {
          path: "billing",
          component: () => import('@/views/admin/billing/Index.vue'),
          children: [
            {
              path: "",
              name: "billing-list",
              meta: {
                auth: ['billing-list']
              },
              component: () => import('@/views/admin/billing/List.vue')
            },
          ]
        },
        {
          path: "people",
          component: () => import('@/views/admin/people/Index.vue'),
          children: [
            {
              path: "",
              name: 'person-list',
              component: () => import('@/views/admin/people/List.vue')
            },
            {
              path: "create",
              name: 'person-create',
              component: () => import('@/views/admin/people/Form.vue')
            },

            {
              path: ":id/edit",
              name: 'person-edit',
              component: () => import('@/views/admin/people/Form.vue')
            },
            {
              path: ":id",
              name: 'person-show',
              component: () => import('@/views/admin/people/Show.vue')
            },
          ]
        },
        {
          path: "personnel",
          component: () => import('@/views/admin/personnel/Index.vue'),
          children: [
            {
              path: "",
              name: "personnel-list",
              component: () => import('@/views/admin/personnel/List.vue')
            },
            {
              path: "create",
              name: "personnel-create",
              component: () => import('@/views/admin/personnel/Form.vue')
            },
            {
              path: ":id",
              name: "personnel-show",
              component: () => import('@/views/admin/personnel/Show.vue')
            },

            {
              path: ":id/edit",
              name: "personnel-edit",
              component: () => import('@/views/admin/personnel/Form.vue')
            }
          ]
        },
        {
          path: "identifications",
          component: () => import('@/views/admin/queries/Index.vue'),
          children: [
            {
              path: "",
              name: "identification-list",
              component: () => import('@/views/admin/queries/List.vue')
            },
            {
              path: "new",
              name: "identification-create",
              component: () => import('@/views/admin/queries/Create.vue')
            },
            {
              path: ":id",
              name: "identification-show",
              component: () => import('@/views/admin/queries/Show.vue')
            },
          ]
        },
        {
          path: "verifications",
          component: () => import('@/views/admin/verifications/Index.vue'),
          children: [
            {
              path: "",
              name: "verification-list",
              component: () => import('@/views/admin/verifications/List.vue')
            },
            {
              path: "new",
              name: "verification-create",
              component: () => import('@/views/admin/verifications/Create.vue')
            },
            {
              path: ":id",
              name: "verification-show",
              component: () => import('@/views/admin/verifications/Show.vue')
            },
          ]
        },
        {
          path: "self",
          component: () => import('@/views/admin/self/Index.vue'),
          children: [
            {
              name: "self-profile",
              path: "profile",
              component: () => import('@/views/admin/self/Profile')
            },
            {
              name: "self-profile-edit",
              path: "profile-edit",
              component: () => import('@/views/admin/self/ProfileEdit')
            },

          ]
        },
        {
          path: "pricing",
          component: () => import('@/views/admin/Pricing/Index.vue'),
          children: [
            {
              path: "",
              name: "pricing",
              component: () => import('@/views/admin/Pricing/List.vue')
            },
            {
              path: 'success',
              name: "success",
              component: () => import('@/views/admin/Pricing/Success.vue')
            },
            {
              path: ":id/invoice-form",
              name: "invoice-form",
              component: () => import('@/views/admin/Pricing/InvoiceForm.vue')
            }
          ]
        },
        {
          name: "admin-dashboard",
          path: "",
          component: () => import("@/views/admin/Dashboard/Dashboard.vue"),
        },
        {
          name: "system-settings",
          path: "system-settings",
          meta: {
            auth: {
              roles: 'system-settings',
            },
          },
          component: () => import("@/views/admin/SystemSettings/Index.vue"),
        },
      ]
    },
    {
      path: "/auth",

      component: () => import("@/views/auth/Index.vue"),
      children: [
        {
          path: "login",
          name: "login",
          meta: { auth: false },
          component: () => import("@/views/auth/Login.vue")
        },
        {
          path: "register",
          name: "register",
          meta: { auth: false },
          component: () => import("@/views/auth/Register.vue")
        },
        {
          path: "forgot-password",
          name: "forgot-password",
          meta: { auth: false },
          component: () => import("@/views/auth/ForgotPassword.vue")
        },
        {
          path: "reset-password",
          name: "reset-password",
          meta: { auth: false },
          component: () => import("@/views/auth/ResetPassword.vue")
        },
        {
          path: "update-password",
          name: "update-password",
          component: () => import("@/views/auth/UpdatePassword.vue")
        },
        {
          path: "verify-email",
          name: "verify-email",
          meta: { auth: true, function: "verify-email" },
          component: () => import("@/views/auth/VerifyEmail.vue")
        }
      ]
    },
    {
      path: "/error",
      component: () => import("@/views/error/Index.vue"),
      children: [
        {
          path: "404",
          name: "notfound",
          component: () => import("@/views/error/Error404.vue"),
        },
        {
          path: "403",
          name: "forbidden",
          component: () => import("@/views/error/Error403.vue"),
        },
      ]
    },
    {
      path: "/",
      redirect: { name: "admin-dashboard" }
    },
    {
      path: "*",
      component: () => import("@/views/error/Error404.vue"),
    },
  ],
})

Vue.router = router;

router.beforeEach((to, from, next) => {
  if (to.meta.auth !== false) {
    const funnel = router.app.$auth?.user()?.funnels?.length ? router.app.$auth?.user()?.funnels[0] : '';
    if (to?.name !== 'verify-email' && funnel === 'email.verify') {
      return next({ name: "verify-email" });
    } else if (to?.name !== 'organization-flow' && funnel === 'organization.create') {
      return next({ name: "organization-flow" });
    }
  }
  next();
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg")
  if (appLoading) {
    appLoading.style.display = "none"
  }
})

export default router
